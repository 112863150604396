import {
  HomeTransfer,
  RemoveDevice,
} from "src/components/Devices/DeviceSettings/DeviceSettingsNodes"
import {
  DEFAULT_REPORT_INTERVAL,
  TReportInterval,
} from "src/data/devices/types/deviceTypes"
import { EditType } from "src/data/editField/editFieldTypes"
import { langKeys } from "src/i18n/langKeys"

import {
  DeviceSettingMixin,
  IDeviceSettings,
  Input,
  Section,
} from "./types/deviceSettingTypes"

export const NOTIFY_ABOUT_NOISE_ID = "reactions.sound_level_high.push"
export const ADVANCED_SETTINGS_SECTION = "advanced settings"

const StaticFirmware: Input.Static = {
  ...DeviceSettingMixin,
  type: EditType.STATIC,
  id: "static.firmware",
  title: langKeys.device_settings_firmware_version,
  info: "",
  defaultValue: "N/A",
  inlineEdit: true,
  hideInBulkMode: true,
  payload: () => undefined,
  storedValue: (settings: IDeviceSettings): number =>
    settings.firmware.installed,
}

export const StaticMAC: Input.Static = {
  ...DeviceSettingMixin,
  type: EditType.STATIC,
  id: "hello static.mac",
  title: langKeys.device_settings_mac_address,
  info: "",
  defaultValue: "N/A",
  inlineEdit: true,
  hideInBulkMode: true,
  payload: () => undefined,
  storedValue: (settings: IDeviceSettings): string => settings.device_mac,
}

const SettingFrequentSensorUpdates: Input.Toggle = {
  ...DeviceSettingMixin,
  type: EditType.TOGGLE,
  id: "report_interval",
  title: langKeys.device_report_rate_setting_title,
  info: langKeys.device_report_rate_setting_body,
  inlineEdit: true,
  falseVal: "default",
  trueVal: "fast",
  defaultValue: "default",
  storedValue: (settings: IDeviceSettings): TReportInterval =>
    settings?.report_interval || DEFAULT_REPORT_INTERVAL,
  payload: (report_interval: TReportInterval) => ({ report_interval }),
}

const SettingNotifyAboutNoise: Input.Dropdown = {
  ...DeviceSettingMixin,
  title: langKeys.device_settings_threshold_sound_switch_title,
  translateDisplayValue: true,
  info: "",
  options: [
    {
      value: "false",
      name: langKeys.device_threshold_noise_notifications_switch_state_only_noise_monitoring,
    },
    {
      value: "true",
      name: langKeys.device_threshold_noise_notifications_switch_state_always,
    },
  ],
  defaultValue: "false",
  id: NOTIFY_ABOUT_NOISE_ID,
  type: EditType.DROPDOWN,
  storedValue: (settings: IDeviceSettings): "false" | "true" => {
    return settings.always_notify_when_noisy ? "true" : "false"
  },
  payload: (value: string) => {
    return { always_notify_when_noisy: value === "true" }
  },
}

export const SectionAdvanced: Section = {
  type: EditType.SECTION,
  key: ADVANCED_SETTINGS_SECTION,
  title: langKeys.device_settings_advanced_settings_title,
  contents: [
    StaticFirmware,
    StaticMAC,
    SettingNotifyAboutNoise,
    SettingFrequentSensorUpdates,
    HomeTransfer,
    RemoveDevice,
  ],
}
