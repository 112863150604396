import { HumidityNode } from "src/components/Devices/DeviceSettings/DeviceSettingsNodes"
import { TReactions } from "src/data/devices/types/deviceTypes"
import { EditType } from "src/data/editField/editFieldTypes"
import { langKeys } from "src/i18n/langKeys"

import {
  DeviceSettingMixin,
  IDeviceSettings,
  Input,
  Section,
  SubSection,
} from "./types/deviceSettingTypes"

export const HUMIDITY_SECTION_KEY = "humidity-section"
export const HUMIDITY_SECTION_KEY_V2 = "humidity-section-v2"
export const INDOOR_CLIMATE_HUMIDITY_SECTION_KEY =
  "indoor-climate-humidity-section"
const HUMIDITY_SUBSECTION_KEY = "humidity-subsection-key"

const NotifyAboutHumidity: Input.Toggle = {
  ...DeviceSettingMixin,
  type: EditType.TOGGLE,
  id: "reactions.type.humidity.enabled",
  title: (t) =>
    t(langKeys.device_settings_threshold_switch_title, {
      "0": t(langKeys.humidity),
    }),

  info: langKeys.device_settings_humidity_detail_description,
  inlineEdit: true,
  trueVal: true,
  falseVal: false,
  defaultValue: false,
  storedValue: (settings: IDeviceSettings): boolean => {
    const reactionTypes: TReactions["type"][] = [
      "home:humidity:low",
      "home:humidity:high",
    ]
    const value = !!settings.configuration?.reactions?.find(
      (r) =>
        reactionTypes.includes(r.type) &&
        r.notifications?.find((n) => n === "push")
    )
    return value
  },
  payload: (value: boolean, settings: IDeviceSettings) => {
    const notifications: "push"[] = !!value ? ["push"] : []
    const reactionTypes: TReactions["type"][] = [
      "home:humidity:low",
      "home:humidity:high",
    ]
    const reactions =
      settings.configuration?.reactions
        .filter((r) => reactionTypes.includes(r.type))
        .map((reaction) => ({ ...reaction, notifications })) || []
    return { reactions }
  },
}

const HumidityAbove: Input.Number = {
  ...DeviceSettingMixin,
  type: EditType.INPUT_NUMBER,
  title: (t) =>
    t(langKeys.device_settings_above, { "0": t(langKeys.humidity) }),
  info: "",
  id: "reactions.type.home:humidity:high",
  unit: "%",
  label: "",
  max: 100,
  min: 0,
  step: 5,
  defaultValue: 60,
  storedValue: (settings: IDeviceSettings) => {
    const reactionType: TReactions["type"] = "home:humidity:high"

    const value =
      settings.configuration?.reactions.find(
        (reaction) => reaction.type === reactionType
      )?.value || HumidityAbove.defaultValue

    return value
  },
  payload: (value: number, settings: IDeviceSettings) => {
    const reactionType: TReactions["type"] = "home:humidity:high"
    // If a threshold is saved, we can safely assume that notifications should
    // also be enabled:
    const notifications: "push"[] = ["push"]
    const reactions =
      settings.configuration?.reactions
        .filter((r) => r.type === reactionType)
        .map((r) => ({ ...r, notifications, value })) || []
    return { reactions }
  },
}

const HumidityBelow: Input.Number = {
  ...HumidityAbove,
  title: (t) =>
    t(langKeys.device_settings_below, { "0": t(langKeys.humidity) }),
  info: "",
  id: "reactions.type.home:humidity:low",
  defaultValue: 30,
  storedValue: (settings: IDeviceSettings) => {
    const reactionType: TReactions["type"] = "home:humidity:low"
    const value: number =
      settings.configuration?.reactions.find(
        (reaction) => reaction.type === reactionType
      )?.value || HumidityBelow.defaultValue
    return Math.round(value)
  },
  payload: (value: number, settings: IDeviceSettings) => {
    const reactionType: TReactions["type"] = "home:humidity:low"
    // If a threshold is saved, we can safely assume that notifications should
    // also be enabled:
    const notifications: "push"[] = ["push"]
    const reactions =
      settings.configuration?.reactions
        .filter((r) => r.type === reactionType)
        .map((r) => ({ ...r, notifications, value })) || []
    return { reactions }
  },
}

const SubSectionHumidity: SubSection = {
  type: EditType.SECTION_TOGGLE,
  fields: [HumidityAbove, HumidityBelow],
  toggle: NotifyAboutHumidity,
}

const SubSectionHumidityIndoorClimate: Section = {
  key: HUMIDITY_SUBSECTION_KEY,
  title: "",
  description: langKeys.humidity_settings_dialog_info,
  type: EditType.SECTION,
  contents: [HumidityAbove, HumidityBelow],
}

export const SectionHumidity: Section = {
  type: EditType.SECTION,
  key: HUMIDITY_SECTION_KEY,
  title: langKeys.humidity,
  contents: [SubSectionHumidity],
}

export const SectionHumidityIndoorClimate: Section = {
  type: EditType.SECTION,
  key: INDOOR_CLIMATE_HUMIDITY_SECTION_KEY,
  title: langKeys.humidity,
  contents: [SubSectionHumidityIndoorClimate],
}

export const SectionHumidityV2: Section = {
  type: EditType.SECTION,
  key: HUMIDITY_SECTION_KEY_V2,
  title: langKeys.humidity,
  contents: [HumidityNode],
}
