import { useState } from "react"
import styled from "styled-components"

import { Switch } from "@material-ui/core"
import { spacing } from "@minuthq/meatball-ui-react"
import { useSnackbar } from "notistack"

import NumberInput from "src/components/Settings/EditField/NumberInput"
import { getDeviceHumidityReactions } from "src/data/devices/logic/deviceLogic"
import { usePutDevice } from "src/data/devices/queries/deviceQueries"
import { TDevice, TReactions } from "src/data/devices/types/deviceTypes"
import { IDeviceSettings } from "src/data/deviceSettings/types/deviceSettingTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useTranslate } from "src/i18n/useTranslate"
import { MDialog } from "src/ui/Dialog/MDialog"
import { MSettingsCard } from "src/ui/MCard/MSettingsCard"
import { MText } from "src/ui/MText"
import { formatDateAsTime } from "src/utils/l10n"

export function HumiditySettings({
  deviceSettings,
}: {
  deviceSettings: IDeviceSettings
}) {
  const { t, langKeys } = useTranslate()

  const defaultLowThreshold = 0
  const defaultHighThreshold = 60

  const [manageOpen, setManageOpen] = useState(false)

  const {
    notificationsEnabled,
    lowThreshold = defaultLowThreshold,
    highThreshold = defaultHighThreshold,
  } = getDeviceHumidityReactions({
    device: deviceSettings,
  })

  return (
    <MSettingsCard
      title={t(langKeys.device_settings_thresholds)}
      description={t(langKeys.device_settings_humidity_info)}
      labeledPills={[
        {
          label: t(langKeys.humidity_below),
          id: "humidity-below",
          value: notificationsEnabled
            ? `${Math.round(lowThreshold)} %`
            : undefined,
          isOn: notificationsEnabled,
        },
        {
          label: t(langKeys.humidity_above),
          id: "humidity-above",
          isOn: notificationsEnabled,
          value: notificationsEnabled
            ? `${Math.round(highThreshold)} %`
            : undefined,
        },
      ]}
      onManageClick={() => setManageOpen(true)}
    >
      <HumidityThresholdDialog
        open={manageOpen}
        onClose={() => setManageOpen(false)}
        deviceId={deviceSettings.device_id}
        notificationsEnabled={notificationsEnabled}
        placedOutdoors={deviceSettings.placed_outdoors}
        thresholds={{
          low: lowThreshold,
          high: highThreshold,
        }}
        nextHeartbeatAt={deviceSettings.next_heartbeat_at}
      />
    </MSettingsCard>
  )
}

function HumidityThresholdDialog({
  open,
  onClose,
  deviceId,
  notificationsEnabled,
  placedOutdoors,
  thresholds,
  nextHeartbeatAt,
}: {
  open: boolean
  onClose: () => void
  deviceId: string
  notificationsEnabled: boolean
  placedOutdoors?: boolean
  thresholds: {
    low: number
    high: number
  }
  nextHeartbeatAt?: TDevice["next_heartbeat_at"]
}) {
  const { t, langKeys } = useTranslate()
  const { clock_type } = useGetUser()

  const { orgId } = useOrganization()

  const { enqueueSnackbar } = useSnackbar()

  const [toggle, setToggle] = useState(notificationsEnabled)

  const [humidityBelow, setHumidityBelow] = useState(thresholds.low)
  const [humidityAbove, setHumidityAbove] = useState(thresholds.high)

  const putDevice = usePutDevice(orgId)
  const showInputs = !placedOutdoors || toggle

  const notifications: TReactions["notifications"] = showInputs ? ["push"] : []

  function handleSave() {
    putDevice.mutate(
      {
        id: deviceId,
        data: {
          reactions: [
            {
              notifications,
              type: "home:humidity:low",
              value: humidityBelow,
            },
            {
              notifications,
              type: "home:humidity:high",
              value: humidityAbove,
            },
          ],
        },
      },
      {
        onSuccess: () => {
          enqueueSnackbar(
            nextHeartbeatAt
              ? t(langKeys.device_settings_will_be_updated_at, {
                  time: formatDateAsTime({
                    date: nextHeartbeatAt,
                    clockType: clock_type,
                    excludeSeconds: true,
                  }),
                })
              : t(langKeys.device_settings_will_be_updated_notification)
          )

          handleClose()
        },
      }
    )
  }

  function handleClose() {
    setToggle(showInputs)
    putDevice.reset()
    onClose()
  }

  return (
    <MDialog
      open={open}
      onClose={handleClose}
      title={t(langKeys.humidity_thresholds)}
      description={t(langKeys.humidity_settings_dialog_info)}
      onConfirm={handleSave}
      confirmLabel={t(langKeys.save)}
      loading={putDevice.isLoading}
      error={putDevice.error && t(langKeys.failed_something_went_wrong)}
    >
      <TextFieldBox>
        {placedOutdoors && (
          <StyledToggleBox>
            <MText variant="subtitle">
              {t(langKeys.device_settings_threshold_switch_title, {
                "0": t(langKeys.humidity).toLowerCase(),
              })}
            </MText>

            <Switch
              checked={toggle}
              onChange={() => setToggle(!toggle)}
              disabled={putDevice.isLoading}
            />
          </StyledToggleBox>
        )}

        {showInputs && (
          <>
            <NumberInput
              min={0}
              max={100}
              label={t(langKeys.humidity_below)}
              value={humidityBelow}
              unit="%"
              setValue={(value) => setHumidityBelow(value)}
            />
            <NumberInput
              min={0}
              max={100}
              label={t(langKeys.humidity_above)}
              value={humidityAbove}
              unit="%"
              setValue={(value) => setHumidityAbove(value)}
            />
          </>
        )}
      </TextFieldBox>
    </MDialog>
  )
}

const TextFieldBox = styled.div`
  display: grid;
  gap: ${spacing.L};
`

const StyledToggleBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing.L};
  align-items: center;
  justify-content: space-between;
`
