import {
  AlarmRecognition,
  DeviceAreaType,
  NightLight,
  WindowBreakDetection,
} from "src/components/Devices/DeviceSettings/DeviceSettingsNodes"
import { DEFAULT_DEVICE_DESCRIPTION } from "src/data/devices/types/deviceTypes"
import { EditType } from "src/data/editField/editFieldTypes"
import { langKeys } from "src/i18n/langKeys"

import {
  DeviceSettingMixin,
  IDeviceSettings,
  Input,
  Section,
} from "./types/deviceSettingTypes"

export const GENERAL_SETTINGS_SECTION = "general settings"

export const DeviceName: Input.Text = {
  ...DeviceSettingMixin,
  type: EditType.INPUT_TEXT,
  id: "device.description",
  title: langKeys.name,
  info: "",
  label: "",
  defaultValue: "",
  maxLength: 50,
  storedValue: (settings: IDeviceSettings): string =>
    settings.description || DEFAULT_DEVICE_DESCRIPTION,
  payload: (description: string) => ({ description }),
}

export const OUTDOOR_SENSOR_ID = "device.outdoor_sensor"
// Disabled until the backend supports toggling this field properly
// const OutdoorSensor: Input.Toggle = {
//   ...DeviceSettingMixin,
//   type: EditType.TOGGLE,
//   id: OUTDOOR_SENSOR_ID,
//   title: "Outdoor sensor",
//   info: "Is the sensor placed outdoors?",
//   inlineEdit: true,
//   trueVal: true,
//   falseVal: false,
//   defaultValue: false,
//   storedValue: (settings: IDeviceSettings): boolean => {
//     return settings.placed_outdoors
//   },
//   payload: (placed_outdoors: boolean) => {
//     return { placed_outdoors }
//   },
//   // hideInBulkMode: true, // enable this if bulk editing doesn't work
// }

export const SectionGeneral: Section = {
  type: EditType.SECTION,
  key: GENERAL_SETTINGS_SECTION,
  title: langKeys.settings_general,
  contents: [
    DeviceName,
    DeviceAreaType,
    NightLight,
    WindowBreakDetection,
    AlarmRecognition,
    // OutdoorSensor,
  ],
}
