import { AlarmRecognitionSetting } from "src/components/Devices/DeviceSettings/AlarmRecognition"
import { SelectSensorPlacement } from "src/components/Devices/DeviceSettings/DeviceAreaType"
import { SettingHomeTransfer } from "src/components/Devices/DeviceSettings/HomeTransfer"
import { HumiditySettings } from "src/components/Devices/DeviceSettings/HumiditySettings"
import { NightLightSetting } from "src/components/Devices/DeviceSettings/NightLight"
import { NoiseMonitoringMoved } from "src/components/Devices/DeviceSettings/NoiseMonitoringMoved"
import { TemperatureSettings } from "src/components/Devices/DeviceSettings/TemperatureSettings"
import { IDeviceSettings } from "src/data/deviceSettings/types/deviceSettingTypes"
import { EditType, IEditFieldNode } from "src/data/editField/editFieldTypes"

import { SettingRemoveDevice } from "./RemoveDevice"
import { WindowBreakDetectionSetting } from "./WindowBreakDetection"

export const AlarmRecognition: IEditFieldNode<IDeviceSettings> = {
  type: EditType.NODE,
  key: "alarm_recognition",
  contents: (settings: IDeviceSettings) => {
    return (
      <AlarmRecognitionSetting
        deviceId={settings.device_id}
        alarmRecognition={settings.alarm_recognition}
      />
    )
  },
  hideInBulkMode: true,
  filterOutNode: (settings: IDeviceSettings) => !!settings.placed_outdoors,
}

export const DeviceAreaType: IEditFieldNode<IDeviceSettings> = {
  type: EditType.NODE,
  key: "area-type",
  contents: (settings: IDeviceSettings) => {
    return (
      <SelectSensorPlacement
        placedOutdoors={!!settings.placed_outdoors}
        initialValue={settings.configuration?.room_type}
        deviceId={settings.device_id}
      />
    )
  },
  hideInBulkMode: true,
}

export const HomeTransfer: IEditFieldNode<IDeviceSettings> = {
  type: EditType.NODE,
  key: "home-transfer-setting",
  contents: (settings: IDeviceSettings) => (
    <SettingHomeTransfer deviceId={settings.device_id} homeId={settings.home} />
  ),
  hideInBulkMode: true,
}

export const NightLight: IEditFieldNode<IDeviceSettings> = {
  type: EditType.NODE,
  key: "night-light",
  contents: (settings: IDeviceSettings) => {
    const defaultSliderRange = "23:00-06:00"
    const start = settings.nightlight_start_at
    const end = settings.nightlight_end_at
    const slider = start && end ? `${start}-${end}` : defaultSliderRange

    return (
      <NightLightSetting
        deviceId={settings.device_id}
        nightLightMode={settings.nightlight_mode}
        nightLightSlider={slider}
      />
    )
  },
  hideInBulkMode: true,
  filterOutNode: (settings: IDeviceSettings) => !!settings.placed_outdoors,
}

export const RemoveDevice: IEditFieldNode<IDeviceSettings> = {
  type: EditType.NODE,
  key: "remove-device-setting",
  contents: (settings: IDeviceSettings) => {
    return <SettingRemoveDevice deviceId={settings.device_id} />
  },
  hideInBulkMode: true,
}

export const WindowBreakDetection: IEditFieldNode<IDeviceSettings> = {
  type: EditType.NODE,
  key: "window-break-detection",
  contents: (settings: IDeviceSettings) => {
    return (
      <WindowBreakDetectionSetting
        deviceId={settings.device_id}
        listeningMode={settings.listening_mode}
      />
    )
  },
  hideInBulkMode: true,
  filterOutNode: (settings: IDeviceSettings) => !!settings.placed_outdoors,
}

export const NOISE_MONITORING_MOVED_SECTION_KEY = "noise-monitoring-moved"

export const NoiseMonitoringMovedNode: IEditFieldNode = {
  type: EditType.NODE,
  key: NOISE_MONITORING_MOVED_SECTION_KEY,
  contents: () => <NoiseMonitoringMoved />,
}

export const HumidityNode: IEditFieldNode<IDeviceSettings> = {
  type: EditType.NODE,
  key: "humidity-node",
  contents: (settings: IDeviceSettings) => {
    return <HumiditySettings deviceSettings={settings} />
  },
  hideInBulkMode: true,
}

export const TemperatureNode: IEditFieldNode<IDeviceSettings> = {
  type: EditType.NODE,
  key: "temperature-node",
  contents: (settings: IDeviceSettings) => {
    return <TemperatureSettings deviceSettings={settings} />
  },
  hideInBulkMode: true,
}
